import * as React from 'react';
// import PropTypes from 'prop-types';
import './article-summary.scss';
import parse from 'html-react-parser';
import { Link } from 'gatsby';
import { FiExternalLink } from '@react-icons/all-files/fi/FiExternalLink'; // https://react-icons.github.io/react-icons
import { GrDocumentPdf } from '@react-icons/all-files/gr/GrDocumentPdf'; // https://react-icons.github.io/react-icons

// brand styles found here:
// http://psunewstest.s3-website-us-east-1.amazonaws.com/storybook/?path=/story/paragraphs-article-text-block-summary--paragraph-summary

const ArticleSummary = ({
	author = '',
	source = '',
	title = '',
	description = '',
	link = '/',
	icon = null, // possible values are null, external, and pdf
	external = false
}) => (
	<div
		className="article-text-block-summary-module__container article-text-block-summary-module__summaryIsParagraph"
		data-testid="article-text-block-summary"
		key={title}
		data-aos="fade-in"
		data-aos-duration="1200"
	>
		<div className="article-text-block-summary-module__by">
			{icon === 'external' ? (
				<FiExternalLink className="fluid-type-lg mb-2" />
			) : (
				''
			)}
			{icon === 'pdf' ? (
				<GrDocumentPdf className="fluid-type-lg mb-2" />
			) : (
				''
			)}
			<div className="author mb-2">{author}</div>
			<div className="source">{source}</div>
		</div>
		<hr className="article-text-block-summary-module__mobileDivider" />
		<div
			className="article-text-block-summary-module__summary"
			data-testid="summary"
		>
			<div
				className="article-text-block-summary-module__summaryParagraph"
				data-testid="summary-paragraph"
			>
				<h2 className="fluid-type-base">
					{external ? (
						<Link to={link}>{title}</Link>
					) : (
						<a href={link}>{title}</a>
					)}
				</h2>
				{parse(description)}
			</div>
		</div>
		<hr className="article-text-block-summary-module__mobileDivider" />
	</div>
);

ArticleSummary.propTypes = {
	// lead: PropTypes.string
};

ArticleSummary.defaultProps = {
	// lead: `Add some tags please`
};

export default ArticleSummary;
