// Search is a page that uses server-side rendering
// it uses a js fetch call using a graphql query and returns json data containing entries
// https://www.gatsbyjs.com/docs/how-to/rendering-options/using-server-side-rendering/
// this page could maybe have used client-only route rendering but went with ssr for simplicity sake
// https://www.gatsbyjs.com/docs/how-to/routing/client-only-routes-and-user-authentication/

import React, { useState, useEffect } from "react"
// import { useLocation } from '@reach/router';
// import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import Seo from "../components/seo"

import useSiteMetadata from "../hooks/use-site-metadata"
import Layout from "../components/layout"
import HeroFeatureStory from "../components/psu-brand/hero-feature-story"
import ArticleSummary from "../components/psu-brand/article-summary"
// import LatestNewsCard from '../components/psu-brand/latest-news-card/latest-news-card';
import ArticleExcerptCard from "../components/psu-brand/article-excerpt-card"
// import { graphql } from 'gatsby';
// import ContentBuilder from '../components/craftcms/content-builder';
import SearchContentBuilderQuery from "../graphql-fragments/search-content-builder-query"
import SearchResourceBuilderQuery from "../graphql-fragments/search-resource-builder-query"
import Spinner from "react-bootstrap/Spinner"

// console.log('location outside: ', location);

// const { siteUrl } = useSiteMetadata();
// const urlParams = new URLSearchParams(location.search);
// const [keywords, setKeywords] = useState(urlParams.get('keywords'));
// const [numResults, setNumResults] = useState(0);

const SearchPage = ({ location, data }) => {
  // console.log('serverData: ', serverData);
  const { siteUrl, craftGraphQlApi } = useSiteMetadata()
  const urlParams = new URLSearchParams(location.search)
  const [keywords, setKeywords] = useState(urlParams.get("keywords"))
  const [entries, setEntries] = useState({})
  const [numResults, setNumResults] = useState(0)
  const [loadingState, setLoadingState] = useState(true)
  // const numResults = 0;

  const getSearchData = () => {
    // make call to api
    // const apiUrl = `${siteUrl}/api`
    const apiUrl = `${craftGraphQlApi}`
    // const apiUrl = 'https://virtual-farm:8890/api';
    // const apiGqlQuery = `
    // 	{
    // 		entries(search: "${keywords}") {
    // 			id
    // 			title
    // 			url
    // 			slug
    // 			sectionHandle
    // 		}
    // 	}
    // `;

    // helps to look at craft's graphiql instead of gatsby's
    // it doesn't have all the "CraftAPI_" stuff in the names
    // https://virtual-farm:8890/admin/graphiql

    const apiGqlQuery = `
			{
				entries(search: "${keywords}") {
					id
					slug
					title
					url
					sectionHandle
					... on fastFacts_default_Entry {
						id
						${SearchContentBuilderQuery()}
					}
					... on virtualFarm_virtualFarm_Entry {
						id
						${SearchContentBuilderQuery()}
					}
					... on resources_default_Entry {
						id
						${SearchResourceBuilderQuery()}
					}
					... on sustainability_default_Entry {
						id
						${SearchContentBuilderQuery()}
					}
					... on farmComponents_default_Entry {
						id
						${SearchContentBuilderQuery()}
					}
					... on about_default_Entry {
						id
						${SearchContentBuilderQuery()}
					}
					... on homepage_homepage_Entry {
						id
						${SearchContentBuilderQuery()}
					}
				}
			}
		`

    const apiRequest = new Request(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/graphql",
        Accept: "application/json",
      },
      // mode: 'no-cors',
      mode: "cors",
      cache: "no-cache",
      body: apiGqlQuery,
    })

    // console.log('apiGqlQuery: ', apiGqlQuery);

    const apiFetch = fetch(apiRequest)
    return apiFetch
  }

  function groupBy(arr, property) {
    return arr.reduce(function (memo, x) {
      if (!memo[x[property]]) {
        memo[x[property]] = []
      }
      memo[x[property]].push(x)
      return memo
    }, {})
  }

  useEffect(() => {
    getSearchData()
      .then(response => {
        console.log("apiFetch response:", response)
        return response.json()
      })
      .then(result => {
        console.log("Result:", result)

        // setEntries(result.data.entries);
        setNumResults(result.data.entries.length)
        setEntries(groupBy(result.data.entries, "sectionHandle"))
        setLoadingState(false)
      })
      .catch(error => {
        console.error("Error: ", error)
      })
  }, [])

  // render results

  return (
    <Layout location={location}>
      <Seo title="Search Results - Virtual Farm" />
      {/* <>{keywords}</> */}
      {/* {console.log('location: ', location)} */}
      {/* {location.search} <br />
			{keywords} */}
      <HeroFeatureStory
        title="Search"
        subheading={
          loadingState ? (
            <Spinner animation="border" variant="light" />
          ) : (
            `Your Search for "${keywords}" returned ${numResults} result${
              numResults === 1 ? "" : "s"
            }`
          )
        }
        children={<p></p>}
        image={[
          {
            extension: "jpg",
            filename: "150-farm.jpg",
            focalPoint: [0.5, 0.5],
            hasFocalPoint: false,
            path: "150-farm.jpg",
            title: "150 farm",
            width: 3840,
            url: `${siteUrl}/uploads/150-farm.jpg`,
            srcset: `${siteUrl}/uploads/_600xAUTO_crop_center-center_none/150-farm.jpg 600w, ${siteUrl}/uploads/_900xAUTO_crop_center-center_none/150-farm.jpg 900w, ${siteUrl}/uploads/_1200xAUTO_crop_center-center_none/150-farm.jpg 1200w, ${siteUrl}/uploads/_1500xAUTO_crop_center-center_none/150-farm.jpg 1500w, ${siteUrl}/uploads/_1800xAUTO_crop_center-center_none/150-farm.jpg 1800w`,
          },
        ]}
        key="resource-hero"
      />
      <div className="container my-5 min-vh-100">
        {/* {console.log('entries: ', entries)} */}

        {loadingState && (
          <div className="text-center">
            <Spinner animation="border" variant="primary" className="my-5" />
          </div>
        )}

        {Object.keys(entries).map(section => {
          // console.log('section: ', section);
          let sectionCap =
            section[0].toUpperCase() +
            section.substring(1).replace(/([A-Z])/, " $1")
          return (
            <div
              className={`search-section section-${section.toLowerCase()} ${
                section !== "resources" ? " d-flex flex-wrap" : ""
              }`}
            >
              <h2 data-aos="fade-right" className="w-100">
                {sectionCap}
              </h2>

              {entries[section].map(entry => {
                // 	// console.log('resource entry: ', entry);

                if (section === "resources") {
                  let author,
                    source,
                    description,
                    link = ""

                  entry.resourceBuilder.map(block => {
                    switch (block.typeHandle) {
                      case "author":
                        author = block.text
                        break
                      case "source":
                        source = block.text
                        break
                      case "description":
                        description = block.text
                        break
                      case "link":
                        link = block.linkUrl

                        break
                    }
                    return null
                  })

                  return (
                    <>
                      <ArticleSummary
                        author={author}
                        source={source}
                        title={entry.title}
                        description={description}
                        link={link}
                        key={entry.id}
                      />
                    </>
                  )
                } else {
                  let description =
                    typeof entry.contentBuilder !== "undefined"
                      ? entry.contentBuilder.filter(b => {
                          if (b.typeHandle === "contentBlock") {
                            return true
                          } else {
                            return false
                          }
                        })
                      : [undefined]
                  console.log("description 1: ", description)

                  if (typeof description[0] !== "undefined") {
                    let tempStr = ""
                    for (let index = 0; index < description.length; index++) {
                      const element = description[index]
                      if (element.contentArea.includes(keywords)) {
                        tempStr +=
                          element.contentArea
                            .replace(/(<([^>]+)>)/gi, " ")
                            .replace(/\s/gi, " ") + " "
                      }
                    }
                    description = tempStr
                  } else {
                    description = ""
                  }

                  console.log("description 2: ", description)

                  // description = description.match(/\<p dir="ltr">.+\<\/p\>/)
                  // match words around keyword and bold keyword
                  const regex = new RegExp(
                    `(?:[a-zA-Z'-]+[^a-zA-Z'-]+){0,14}${keywords}(?:[^a-zA-Z'-]+[a-zA-Z'-]+){0,14}`,
                    "gi"
                  )
                  const regex2 = new RegExp(`(${keywords})`, "gi")
                  description =
                    description.match(regex) !== null
                      ? description
                          .match(regex)[0]
                          .replace(regex2, "<strong>$1</strong>") + "..."
                      : ""

                  console.log("description 3: ", description)

                  let defaultImage = {
                    extension: "jpg",
                    filename: "150-farm.jpg",
                    focalPoint: [0.5, 0.5],
                    hasFocalPoint: false,
                    path: "150-farm.jpg",
                    title: "150 farm",
                    width: 3840,
                    url: `${siteUrl}/uploads/150-farm.jpg`,
                    srcset: `${siteUrl}/uploads/_600xAUTO_crop_center-center_none/150-farm.jpg 600w, ${siteUrl}/uploads/_900xAUTO_crop_center-center_none/150-farm.jpg 900w, ${siteUrl}/uploads/_1200xAUTO_crop_center-center_none/150-farm.jpg 1200w, ${siteUrl}/uploads/_1500xAUTO_crop_center-center_none/150-farm.jpg 1500w, ${siteUrl}/uploads/_1800xAUTO_crop_center-center_none/150-farm.jpg 1800w`,
                  }
                  let entryImage =
                    typeof entry.contentBuilder !== "undefined"
                      ? entry.contentBuilder.filter(b => {
                          if (b.typeHandle === "heroFeatureStory") {
                            return true
                          } else {
                            return false
                          }
                        })
                      : [undefined]
                  entryImage =
                    typeof entryImage[0] !== "undefined"
                      ? entryImage[0].image[0]
                      : defaultImage
                  if (section === "fastFacts") entryImage = null
                  // console.log('entryImage', entryImage);
                  return (
                    <>
                      <ArticleExcerptCard
                        title={entry.title}
                        description={description}
                        link={entry.url}
                        section={section}
                        key={entry.id}
                        image={entryImage}
                        className="w-100 w-md-50 w-xl-30"
                      />
                    </>
                  )
                }
              })}
            </div>
          )
        })}
      </div>
    </Layout>
  )
}
export default SearchPage

// const GetKeywords = () => {
// 	const location = useLocation();
// 	const urlParams = new URLSearchParams(location.search);
// 	const keywords = urlParams.get('keywords');
// 	return keywords;
// };
// export { GetKeywords };

// const GetSiteUrl = () => {
// 	const { siteUrl } = useSiteMetadata();
// 	return siteUrl;
// };
