// https://www.gatsbyjs.com/docs/reference/graphql-data-layer/using-graphql-fragments/
// Search only builds a string used in fetch to live craft graphql api

// import React from 'react';
// import { graphql } from 'gatsby';
import SearchHeroBespokeBlockQuery from "./search-hero-bespoke-block-query"
import SearchHeroFeatureStoryBlockQuery from "./search-hero-feature-story-block-query"
import SearchContentBlockQuery from "./search-content-block-query"

export default function SearchContentBuilderQuery(props) {
  // return { ...props };
  return query
}

export const query = `
	contentBuilder {
		${SearchHeroBespokeBlockQuery()}
		${SearchHeroFeatureStoryBlockQuery()}
		${SearchContentBlockQuery()}
	}
`
