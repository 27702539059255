// https://www.gatsbyjs.com/docs/reference/graphql-data-layer/using-graphql-fragments/
// Search only builds a string used in fetch to live craft graphql api

// import React from 'react';
// import { graphql } from 'gatsby';

export default function SearchResourceBuilderQuery(props) {
  // return { ...props };
  return query
}

export const query = `
	resourceBuilder {
		... on resourceBuilder_subtitle_BlockType {
			id
			text
			typeHandle
		}
		... on resourceBuilder_author_BlockType {
			id
			text
			typeHandle
		}
		... on resourceBuilder_source_BlockType {
			id
			typeHandle
			text
		}
		... on resourceBuilder_relatedEntries_BlockType {
			id
			typeHandle
			entry {
				id
				title
				slug
			}
		}
		... on resourceBuilder_link_BlockType {
			id
			typeHandle
			title
			linkUrl
		}
		... on resourceBuilder_fileUpload_BlockType {
			id
			typeHandle
			title
		}
		... on resourceBuilder_resourceType_BlockType {
			id
			typeHandle
			title
		}
		... on resourceBuilder_description_BlockType {
			id
			text
			typeHandle
		}
		... on resourceBuilder_year_BlockType {
			id
			typeHandle
			number
		}
		... on resourceBuilder_location_BlockType {
			id
			typeHandle
			text
		}
	}
`
