// https://www.gatsbyjs.com/docs/reference/graphql-data-layer/using-graphql-fragments/
// Search only builds a string used in fetch to live craft graphql api

// import React from 'react';
// import { graphql } from 'gatsby';

export default function SearchHeroFeatureStoryBlockQuery(props) {
  // return { ...props };
  return query
}

export const query = `... on contentBuilder_heroFeatureStory_BlockType {
		typeHandle
		id
		subheading
		sectionOverride
		image {
			extension
			filename
			focalPoint
			hasFocalPoint
			path
			title
			width
			url
			srcset(sizes: ["600", "900", "1200", "1500", "1800"])
		}
	}
`
