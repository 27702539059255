// https://www.gatsbyjs.com/docs/reference/graphql-data-layer/using-graphql-fragments/
// Search only builds a string used in fetch to live craft graphql api

// import React from 'react';
// import { graphql } from 'gatsby';
export default function SearchContentBlockQuery(props) {
  // return { ...props };
  return query
}

export const query = `
	... on contentBuilder_contentBlock_BlockType {
		typeHandle
		id
		contentArea
	}
`
