import * as React from "react"
// import PropTypes from 'prop-types';
import "./article-summary.scss"
import parse from "html-react-parser"
import { Link } from "gatsby"
import Card from "react-bootstrap/Card"
import CraftImage from "../craftcms/craft-image"
import useSiteMetadata from "../../hooks/use-site-metadata"
// import { FiExternalLink } from '@react-icons/all-files/fi/FiExternalLink'; // https://react-icons.github.io/react-icons
// import { GrDocumentPdf } from '@react-icons/all-files/gr/GrDocumentPdf'; // https://react-icons.github.io/react-icons

// brand styles found here:
// http://psunewstest.s3-website-us-east-1.amazonaws.com/storybook/?path=/story/paragraphs-article-text-block-summary--paragraph-summary

const ArticleExcerptCard = ({
  image = {
    extension: "jpg",
    filename: "150-farm.jpg",
    focalPoint: [0.5, 0.5],
    hasFocalPoint: false,
    path: "150-farm.jpg",
    title: "150 farm",
    width: 3840,
    url: `/uploads/150-farm.jpg`,
    srcset: `/uploads/_600xAUTO_crop_center-center_none/150-farm.jpg 600w, /uploads/_900xAUTO_crop_center-center_none/150-farm.jpg 900w, /uploads/_1200xAUTO_crop_center-center_none/150-farm.jpg 1200w, /uploads/_1500xAUTO_crop_center-center_none/150-farm.jpg 1500w, /uploads/_1800xAUTO_crop_center-center_none/150-farm.jpg 1800w`,
  },
  title = "title",
  description = "description",
  link = "/",
  className = "",
}) => {
  const { siteUrl } = useSiteMetadata()
  console.log("siteUrl: ", siteUrl)

  return (
    <Card
      className={className + " p-3 border-0"}
      data-testid="article-excerpt-card"
      key={title}
      data-aos="fade-in"
      data-aos-duration="1200"
    >
      {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
      {image !== null && (
        <CraftImage
          srcset={image.srcset}
          alt={image.title}
          url={image.url}
          className="vh-30 object-fit-cover"
          loading="lazy"
          sizes="20vw"
        />
      )}
      <Card.Body className="ps-0">
        <Card.Title>
          {link.includes(siteUrl) && <Link to={link}>{title}</Link>}
          {!link.includes(siteUrl) && <a href={link}>{title}</a>}
        </Card.Title>
        <Card.Text className="fluid-type-sm">{parse(description)}</Card.Text>
        {/* <Button variant="primary">Go somewhere</Button> */}
      </Card.Body>
    </Card>
  )
}

ArticleExcerptCard.propTypes = {
  // lead: PropTypes.string
}

ArticleExcerptCard.defaultProps = {
  // lead: `Add some tags please`
}

export default ArticleExcerptCard
